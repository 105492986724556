import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout/Layout"
import Article from "../../../components/Article/Article"
import CallToAction from "../../../components/Article/CallToAction"
import Breadcrumbs from "../../../components/Breadcrumbs/Breadcrumbs"
import A from "../../../components/FastLink/A"
import i18n from "i18next"

const SeoArticle = ({ data }) => {
  useEffect(() => {
    i18n.changeLanguage("pl")
  }, [])
  return (
    <Layout
      seo={{
        title: "Dostosowanie strony do urządzeń mobilnych jako podstawa działań SEO",
        description:
          "Zadbanie o zgodność strony WWW z urządzeniami mobilnymi to dziś kluczowy element optymalizacji. Sprawdź, czym jest meta viewport i mobile first index. ",
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Główna",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/baza-wiedzy/artykuly/",
                name: "Baza wiedzy",
              },
            },
            {
              "@type": "ListItem",
              position: 3,
              item: {
                "@id":
                  "/baza-wiedzy/artykul/dostosowanie-strony-internetowej-do-urzadzen-mobilnych-jako-element-optymalizacji-seo/",
                name: "Dostosowanie strony do urządzeń mobilnych jako podstawa działań SEO",
              },
            },
          ],
        },
      }}
    >
      <Breadcrumbs
        path={[
          { url: "/", text: "Główna" },
          {
            url: "/baza-wiedzy/artykuly/",
            text: "Baza wiedzy / Artykuły",
          },
          {
            url: "/baza-wiedzy/artykul/dostosowanie-strony-internetowej-do-urzadzen-mobilnych-jako-element-optymalizacji-seo",
            text: "Dostosowanie strony internetowej do urządzeń mobilnych jako element optymalizacji SEO",
          },
        ]}
      />
      <Article
        img={
          <img
            src={data.file.childImageSharp.fluid.src}
            alt={"Dostosowanie strony internetowej do urządzeń mobilnych jako element optymalizacji SEO"}
          />
        }
      >
        <h1>Dostosowanie strony internetowej do urządzeń mobilnych jako element optymalizacji SEO</h1>
        <p>
          <strong>
            Strona internetowa przyjazna urządzeniom mobilnym jest dziś koniecznością. Coraz więcej osób korzysta bowiem
            z Internetu nie tylko na komputerach, lecz także na smartfonach czy tabletach. Responsywna strona WWW
            dopasowuje się do różnych rozdzielczości ekranów urządzeń mobilnych. Dzięki temu witryna zapewnia klientowi
            dostęp do swojej oferty, jest bardziej funkcjonalna i czytelna. Responsywność jest też bardzo ważna z punktu
            widzenia SEO. Pozwala robotom Google na poprawne zaindeksowanie strony w wersji desktopowej i mobilnej oraz
            osiąganie wyższych pozycji w wynikach wyszukiwania.
          </strong>
        </p>
        <h2>Co oznacza zgodność z urządzeniami mobilnymi?</h2>
        <p>
          Trudno nie zgodzić się ze stwierdzeniem, że urządzenia mobilne zmieniają świat i wpływają na zachowania
          użytkowników Internetu. Niemal każdy z nas ma codziennie przy sobie smartfon, który już od dawna służy nam nie
          tylko do komunikacji z ludźmi, ale też do wyszukiwania informacji. We wrześniu 2020 roku liczba internautów
          wynosiła ogółem 27,7 mln, z czego aż <strong>24,4 mln osób to użytkownicy właśnie urządzeń mobilnych</strong>
          <a className="top-index" href="#przypisy">
            [1]
          </a>{" "}
          , takich jak smartfony i tablety.1 Z tego właśnie powodu tak ważne jest, by strony internetowe były
          responsywne, a więc <strong>dostosowane do każdego ekranu, niezależnie od jego wielkości</strong>.
        </p>
        <p>
          Strona responsywna prawidłowo wyświetla się na urządzeniach mobilnych, dzięki czemu treść jest czytelna, a
          strona wygodna, łatwa w obsłudze i funkcjonalna. Jeśli zaś układ witryny nie jest dopasowany do małego ekranu,
          zniechęca to potencjalnego klienta do zapoznania się z ofertą wskutek czego zazwyczaj porzuca on daną stronę.{" "}
        </p>
        <p className="inserted-seo-link">
          Sprawdź,{" "}
          <a
            href="https://blog.wenet.pl/seo-pozycjonowanie/czym-jest-audyt-seo-dlaczego-jest-potrzebny-wlascicielom-stron-www/"
            target="_blank"
            rel="noopener, noreferrer"
          >
            co to jest audyt SEO
          </a>
          . Dowiedz się już teraz!
        </p>
        <p>
          <strong>
            Zgodność strony internetowej z urządzeniami mobilnymi jest bardzo istotna nie tylko dla użytkowników, ale
            oczywiście także dla robotów Google
          </strong>
          , które taką stronę mogą poprawnie zaindeksować i umieszczać ją wyżej w wynikach wyszukiwania. Strony
          nieresponsywne, niefunkcjonalne i utrudniające korzystanie z nich użytkownikom mobilnym, często mają problem z
          osiągnięciem wysokich pozycji, a co za tym idzie, mają mniejszą widoczność dla internautów, co jest
          szczególnie zauważalne w mobilnych wynikach wyszukiwania.{" "}
        </p>
        <h2>Czym jest meta viewport?</h2>
        <p>
          <strong>Meta viewport</strong> to znacznik HTML używany do optymalizacji strony internetowej, który określa
          sposób wyświetlania się danej witryny na urządzeniach mobilnych. Pozwala ustawić m.in. opcje skalowania strony
          i domyślne przybliżenie. Znacznik ten, podobnie jak inne meta tagi, stosowany jest w sekcji HEAD strony i
          wygląda następująco:
        </p>
        <blockquote>&lt;meta name="viewport" content="..."&gt;</blockquote>
        <p>
          Wartością atrybutu content są konkretne dyrektywy, które można wpisać, oddzielając je przecinkami. To właśnie
          te dyrektywy określają, jak strona WWW wyświetla się na urządzeniach mobilnych. Pamiętajmy, że ich użycie jest
          opcjonalne, a część z nich nie jest obsługiwana przez wszystkie urządzenia i przeglądarki.{" "}
        </p>
        <p>Te wartości to:</p>
        <ul>
          <li>initial scale,</li>
          <li>width, height,</li>
          <li>minimum-scale, maximum-scale,</li>
          <li>user-scalable.</li>
        </ul>
        <p>Najczęstszym zastosowaniem meta viewport jest taka oto wartość:</p>
        <blockquote>&lt;meta name=viewport content="width=device-width, initial-scale=1"&gt;</blockquote>
        <p className="inserted-seo-link">
          Wykonaj <a href="/">audyt strony internetowej</a> i ulepsz jej widoczność w wyszukiwarce.
        </p>
        <p>
          Oznacza to, że strona będzie rozciągnięta na 100% szerokości ekranu oraz nie będzie skalowana (chociaż
          użytkownik będzie mógł ją przybliżyć lub oddalić).
        </p>
        <CallToAction
          to={"/"}
          text={"Wykonaj darmowy audyt strony i popraw jej widoczność w wyszukiwarce. Wykonaj teraz >>"}
        />
        <h2>Czym jest mobile first index?</h2>
        <p>
          Mobile first index jest odpowiedzią na rosnący trend korzystania z urządzeń mobilnych. Oznacza on, że{" "}
          <strong>
            wersja mobilna strony internetowej jest kluczowa dla Google i na jej podstawie oceniana jest pozycja w
            wyszukiwarce
          </strong>
          . Ma to na celu umożliwić użytkownikom mobilnym znalezienie tego, czego szukają i wygodne korzystać ze strony.{" "}
        </p>
        <p>
          Warto zaznaczyć, że chodzi tutaj o <em>mobile first</em>, a nie <em>mobile only index</em>. Jeśli zatem dana
          strona nie ma wersji mobilnej, w procesie indeksowania wciąż będzie brana pod uwagę wersja desktopowa. Może
          mieć to jednak niekorzystny wpływ na jej pozycję w wynikach wyszukiwania. Strona przyjazna dla urządzeń
          mobilnych może z kolei liczyć na wyższe miejsca w rankingu. Należy dodać, iż od chwili zaimplementowania
          mobile first index, mobilna wersja strony WWW może wpływać na wyniki w wyszukiwarce, nawet gdy użytkownik
          będzie wyszukiwał z poziomu komputera.{" "}
        </p>
        <p>
          Według Google strony responsywne, a więc takie, które automatycznie dopasowują się do rozmiaru ekranu i okna
          przeglądarki są odpowiednio przygotowane na mobile first indexing. To samo dotyczy strony, która ma dwie
          wersje, mobilną i desktopową, ale są one sobie równe. Jednak w momencie, gdy strona ma osobne treści na
          urządzenia mobilne i komputery, warto zastosować zalecane przez Google praktyki. Najlepszą praktyką jest
          sytuacja, w której treści, meta dane, tagi, dane strukturalne czy linki wewnętrzne są na wersji mobilnej i
          desktopowej identyczne. Mobilny użytkownik powinien znaleźć taki sam content, jak ten, który zobaczyłby na
          stronie, korzystając z komputera. Nie można jednak wówczas zapomnieć o odpowiednim oznaczeniu strony, po to,
          by uniknąć duplikacji treści. W tym celu zalecane jest dodanie odpowiednich adnotacji. Do strony na komputery
          trzeba dodać rel="alternate", który wskazuje właściwy adres URL na urządzenia mobilne. Do strony na urządzenia
          mobilne zaś rel="canonical", wskazujący odpowiedni adres URL na komputery. Jeśli zaś chodzi o stronę
          responsywną, to nie musi być ona w żaden sposób oznaczana, ponieważ jest to jedna wersja strony i występuje
          pod tymi samymi adresami URL zarówno dla użytkowników mobilnych, jak i desktopowych.{" "}
        </p>
        <p>
          Jakie jeszcze <strong>działania pod kątem SEO</strong> trzeba podjąć, by strona mobilna mogła znaleźć się jak
          najwyżej w wynikach wyszukiwania? Należy zwrócić uwagę przede wszystkim na prędkość ładowania strony i zadbać,
          by działała ona szybko i sprawnie, co jest kluczowym aspektem dla wersji mobilnych. Warto oczywiście zadbać
          także o optymalizację meta title, meta description, nagłówków i treści oraz prowadzić ciągły monitoring
          strony, by na bieżąco wykrywać ewentualne błędy i wprowadzać niezbędne zmiany zgodne z aktualnymi wymaganiami
          algorytmów Google.{" "}
        </p>
        <hr />
        <div className="przypisy" id="przypisy" style={{ color: "#707070" }}>
          Przypisy: <br />
          [1] -{" "}
          <A to="https://www.gemius.pl/wszystkie-artykuly-aktualnosci/wyniki-badania-mediapanel-za-sierpien-2021.html" />
        </div>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "img/full/mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100, jpegQuality: 90) {
          sizes
          src
        }
      }
    }
  }
`

export default SeoArticle
